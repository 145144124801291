import React, { useEffect, useRef } from "react";

export default function FooterInfo() {

        const phoneNumber = '918976950636'; // Replace with your phone number
  const message = 'Hi, Let us know your requirement';
  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  const buttonStyle = {
        position: 'fixed',
        bottom: '40px',
        right: '25px',
        backgroundColor: '#25D366',
        color: 'white',
        borderRadius: '50%',
        width: '60px',
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        zIndex: '1000',
        cursor: 'pointer',
      };

      
  const textRefs = useRef([]);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate-slide-up");
          observer.unobserve(entry.target); // Only animate once
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    textRefs.current.forEach((el) => {
      if (el) {
        observer.observe(el);
      }
    });

    return () => {
      textRefs.current.forEach((el) => {
        if (el) {
          observer.unobserve(el);
        }
      });
    };
  }, []);

  return (
    <div className="footer-info">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-12">
            <p ref={(el) => textRefs.current.push(el)}>
              SUPREME BUSINESS PARK, 701-702 A, Hiranandani Gardens, Powai,
              Mumbai, Maharashtra 400076
            </p>
            <p
              style={{ fontWeight: "700" }}
              className="desktop-hide"
              ref={(el) => textRefs.current.push(el)}
            >
              <span>
                <i className="fa-solid fa-phone-volume"></i>
              </span>
              +91 8976950636
            </p>
          </div>

          <div className="col-md-6 col-12 text-right">
            <p className="" ref={(el) => textRefs.current.push(el)}>
              2023 © Sky Dreamers. All rights reserved.
            </p>
            <div
              className="social-icons desktop-hide"
              ref={(el) => textRefs.current.push(el)}
            >
              {/* <a href="/"><i className="fab fa-facebook-f icon"></i></a>  */}
              <a  target="_blank"  href="https://www.instagram.com/sky_dreamers_club/"><i className="fab fa-instagram icon"></i></a>
              {/* <a href="/"><i className="fab fa-twitter icon"></i></a> */}
              <a  target="_blank"  href="https://www.linkedin.com/company/sky-dreamers-club"><i className="fab fa-linkedin-in icon"></i></a>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-12">
            <p
              style={{ fontWeight: "700" }}
              className="mobile-hide"
              ref={(el) => textRefs.current.push(el)}
            >
              <span>
                <i className="fa-solid fa-phone-volume"></i>
              </span>
              +91 8976950636
            </p>
          </div>

          <div className="col-md-6 col-12 text-right">
            <div
              className="social-icons mobile-hide"
              ref={(el) => textRefs.current.push(el)}
            >
              {/* <a href="/"><i className="fab fa-facebook-f icon"></i></a>  */}
              <a target="_blank" href="https://www.instagram.com/sky_dreamers_club/"><i className="fab fa-instagram icon"></i></a>
              {/* <a href="/"><i className="fab fa-twitter icon"></i></a> */}
              <a  target="_blank"  href="https://www.linkedin.com/company/sky-dreamers-club"><i className="fab fa-linkedin-in icon"></i></a>
            </div>
          </div>
        </div>
      </div>
      {/* <a href={whatsappUrl} target="_blank" className="whatsapp-icon" rel="noopener noreferrer" style={buttonStyle}>
      <i className="fab fa-whatsapp" style={{ fontSize: '24px' }}></i>
    </a> */}

    
    </div>

  );
}
