import React, { useEffect, useRef } from "react";

export default function CTASection() {
  const elementsRef = useRef([]);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-slide-up');
          observer.unobserve(entry.target); // Only animate once
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    elementsRef.current.forEach(el => {
      if (el) {
        observer.observe(el);
      }
    });

    return () => {
      elementsRef.current.forEach(el => {
        if (el) {
          observer.unobserve(el);
        }
      });
    };
  }, []);

  return (
    <div className="cta-banner">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-7 col-9">
            <h4 ref={el => elementsRef.current.push(el)}>
              At Sky Dreamers Club we don't just create digital products, we
              craft digital masterpieces that elevate your brand and drive your
              business forward.
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}
