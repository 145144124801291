import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import OneStepImg from '../assets/images/testo/viraj.jpg';
import RoomanticImg from '../assets/images/testo/nilesh.PNG';
import ARMOURHeavyIMG from '../assets/images/testo/rahul.png';
import OneClickIMG from '../assets/images/testo/sachin.jpeg';
import MsInfraconIMG from '../assets/images/testo/vishwajeet.webp';
import JeharospaceIMG from '../assets/images/testo/vishal.jpg';
import travyfollowsIMG from '../assets/images/testo/UTTAM.jpeg';
import LeftArrowIcon from '../assets/images/left-arrow.png';
import RightArrowIcon from '../assets/images/right-arrow.png';

const testimonials = [
  {
    content: "The dedication they demonstrate towards the project is highly commendable. Their team consistently goes above and beyond, ensuring every detail is meticulously attended to and executed flawlessly.",
    name: "ONE STEP PRINT",
    designation: "VIRAJ- FOUNDER",
    imgSrc: OneStepImg
  },
  {
    content: "As a business owner, I previously lacked clarity on how to establish a truly distinct digital presence. After collaborating with this team, I gained invaluable insights revolutionizing my company's online strategy.",
    name: "ROOM ANTIQUE UK",
    designation: "NILESH- FOUNDER",
    imgSrc: RoomanticImg
  },
  {
    content: "Before working with Sky Dreamers, my website struggled with a high bounce rate and low average visit duration. Their strategic approach led to remarkable improvements, greatly enhancing our online engagement.",
    name: "ARMOUR HEAVY",
    designation: "Captain RAHUL RAI- FOUNDER",
    imgSrc: ARMOURHeavyIMG
  },
  {
    content: "The vision of this startup lies in their unique problem-solving approach. Regardless of your industry, they provide tailored solutions that address specific needs and goals with unmatched expertise.",
    name: "1 CLICK GLOBAL",
    designation: "SACHIN MALIK- MD",
    imgSrc: OneClickIMG
  },
  {
    content: "Sky Dreamers team of seasoned professionals transformed our website with innovative solutions. Their deep industry expertise and collaborative approach delivered tangible results, greatly benefiting our business.",
    name: "MS INFRACON",
    designation: "Vishwajeet Sood- Partner",
    imgSrc: MsInfraconIMG
  },
  {
    content: "After searching for the right agency, we found a minimalistic design approach that communicates our value. They provided comprehensive website design and development within a deadline.",
    name: "JEH AEROSPACE",
    designation: "NEEL- Chief Marketing Officer",
    imgSrc: JeharospaceIMG
  },
  {
    content: "In a crowded market of agencies, finding one that understands client needs and delivers impactful solutions is challenging. This team stands out with their expertise and commitment to excellence.",
    name: "TRAVY FELLOWS",
    designation: "UTTAM AHLAWAT- MD",
    imgSrc: travyfollowsIMG
  }
];


const HomeTestimonials = () => {
  const sliderRef = useRef(null);
  const elementsRef = useRef([]);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-slide-up');
          observer.unobserve(entry.target); // Only animate once
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    elementsRef.current.forEach(el => {
      if (el) {
        observer.observe(el);
      }
    });

    return () => {
      elementsRef.current.forEach(el => {
        if (el) {
          observer.unobserve(el);
        }
      });
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // 3 seconds per slide
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  

  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };

  const goToNext = () => {
    sliderRef.current.slickNext();
  };

  return (
    <div className='testimonials-sec'>
      <div className='container'>
        <div className='row mb-5'>
          <div className='col-md-8 col-12'>
            <div className={`heading-sec animate-slide-up`} ref={el => elementsRef.current.push(el)}>
              <h2>Our Customer Feedback</h2>
              <p>Don’t take our word for it. Trust our customers</p>
            </div>
          </div>

          <div className='col-md-4 col-12'>
            <div className="custom-navigation mobile-hide">
              <img src={LeftArrowIcon} alt="Previous" className="prev-btn" onClick={goToPrev} />
              <div className="nav-gap" />
              <img src={RightArrowIcon} alt="Next" className="next-btn" onClick={goToNext} />
            </div>
          </div>
        </div>
        
        {/* Custom navigation buttons */}
       
        <Slider ref={sliderRef} {...settings} style={{overflowX:'hidden'}}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className='testimonial-card' ref={el => elementsRef.current.push(el)}>
              <p className='testimonial-content'>{testimonial.content}</p>
              <div className='user-info'>
                <img src={testimonial.imgSrc} alt={`${testimonial.name}`} />
                <div>
                  <p className='name'>{testimonial.name}</p>
                  <p className='designation'>{testimonial.designation}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default HomeTestimonials;
