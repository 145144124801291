import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
const OurServices = () => {
    const services = [
        { title: 'UI/UX Designing', description: 'Creating intuitive and engaging user experiences that captivate and convert' },
        { title: 'Website & App Development', description: 'Building robust, scalable, and visually stunning websites and applications.' },
        { title: 'Front End Development', description: 'Crafting seamless and responsive interfaces using: HTML, CSS: The building blocks of web design' },
        { title: 'ReactJs/NextJs', description: 'Building robust, scalable, and visually stunning websites and applications.' },
        { title: 'Flutter, JetPack, Swift', description: 'Cutting-edge mobile app development.' },
        { title: 'Shopify, WordPress', description: 'Powerful e-commerce and content management solutions.' },
        { title: 'Back End Development', description: 'Ensuring powerful and efficient back-end systems with: Spring Boot: Enterprise-level Java applications.' },
        { title: 'Node Js', description: 'Fast and scalable server-side solutions.' },
        { title: 'PHP', description: 'Versatile and widely-used scripting language.' },
        { title: 'MongoDB', description: 'Flexible and scalable NoSQL databases' },
    ];

    const ourServicesRef = useRef(null);
    const desktopServicesRef = useRef([]);
    const mobileServicesRef = useRef([]);

    useEffect(() => {
        const observerOptions = {
            threshold: 0.1
        };

        const observerCallback = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animate-desktop');
                }
            });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        if (ourServicesRef.current) {
            observer.observe(ourServicesRef.current);
        }

        desktopServicesRef.current.forEach(el => {
            if (el) {
                observer.observe(el);
            }
        });

        const mobileObserverCallback = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animate-mobile');
                }
            });
        };

        const mobileObserver = new IntersectionObserver(mobileObserverCallback, observerOptions);

        mobileServicesRef.current.forEach(el => {
            if (el) {
                mobileObserver.observe(el);
            }
        });

        return () => {
            if (ourServicesRef.current) {
                observer.unobserve(ourServicesRef.current);
            }

            desktopServicesRef.current.forEach(el => {
                if (el) {
                    observer.unobserve(el);
                }
            });

            mobileServicesRef.current.forEach(el => {
                if (el) {
                    mobileObserver.unobserve(el);
                }
            });
        };
    }, []);

    return (
        <div className='our-services' >
            <div className='container'>
                <h2>Our Services</h2>

                <div className='mobile-hide'>
                    <div className='services-boxed'>
                        {services.map((service, index) => (
                            <div key={index} className='services-main' ref={(el) => (desktopServicesRef.current[index] = el)}>
                                <div className='row'>
                                    <div className='col-md-1 col-1'>
                                        {/* SVG Icons (Adjust SVG paths as needed) */}
                                        <svg xmlns="http://www.w3.org/2000/svg" className='mobile-hide mx-auto' width="39" height="39" viewBox="0 0 39 39" fill="none">
                                            <path d="M36.8089 0H1.47236C0.659615 0 0 0.659615 0 1.47236C0 2.2851 0.659615 2.94471 1.47236 2.94471H33.2539L0.4314 35.7679C-0.143555 36.3429 -0.143555 37.2749 0.4314 37.8498C0.719246 38.1377 1.09543 38.2812 1.47236 38.2812C1.84928 38.2812 2.2262 38.1377 2.51331 37.8498L35.3365 5.02662V36.8089C35.3365 37.6216 35.9962 38.2812 36.8089 38.2812C37.6216 38.2812 38.2812 37.6216 38.2812 36.8089V1.47236C38.2812 0.659615 37.6216 0 36.8089 0Z" fill="white" />
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" className='desktop-hide mx-auto' width="20" height="21" viewBox="0 0 20 21" fill="none">
                                            <path d="M19.0268 0H0.761071C0.34096 0 0 0.349462 0 0.780048C0 1.21063 0.34096 1.5601 0.761071 1.5601H17.1892L0.222994 18.9497C-0.0742044 19.2543 -0.0742044 19.7481 0.222994 20.0527C0.371783 20.2052 0.566237 20.2812 0.761071 20.2812C0.955905 20.2812 1.15074 20.2052 1.29915 20.0527L18.2657 2.66308V19.5012C18.2657 19.9318 18.6067 20.2812 19.0268 20.2812C19.4469 20.2812 19.7878 19.9318 19.7878 19.5012V0.780048C19.7878 0.349462 19.4469 0 19.0268 0Z" fill="white" />
                                        </svg>
                                    </div>
                                    <div className='col-md-6 col-11'>
                                        <h4>{service.title}</h4>
                                    </div>
                                    <div className='col-md-4 col-12'>
                                        <p>{service.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className='desktop-hide'>
                    <div className='services-boxed'>
                        {services.map((service, index) => (
                            <div key={index} className='services-mainmobile' ref={(el) => (mobileServicesRef.current[index] = el)}>
                                <div className='row'>
                                    <div className='col-md-1 col-1'>
                                        {/* SVG Icons (Adjust SVG paths as needed) */}
                                        <svg xmlns="http://www.w3.org/2000/svg" className='mobile-hide mx-auto' width="39" height="39" viewBox="0 0 39 39" fill="none">
                                            <path d="M36.8089 0H1.47236C0.659615 0 0 0.659615 0 1.47236C0 2.2851 0.659615 2.94471 1.47236 2.94471H33.2539L0.4314 35.7679C-0.143555 36.3429 -0.143555 37.2749 0.4314 37.8498C0.719246 38.1377 1.09543 38.2812 1.47236 38.2812C1.84928 38.2812 2.2262 38.1377 2.51331 37.8498L35.3365 5.02662V36.8089C35.3365 37.6216 35.9962 38.2812 36.8089 38.2812C37.6216 38.2812 38.2812 37.6216 38.2812 36.8089V1.47236C38.2812 0.659615 37.6216 0 36.8089 0Z" fill="white" />
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" className='desktop-hide mx-auto' width="20" height="21" viewBox="0 0 20 21" fill="none">
                                            <path d="M19.0268 0H0.761071C0.34096 0 0 0.349462 0 0.780048C0 1.21063 0.34096 1.5601 0.761071 1.5601H17.1892L0.222994 18.9497C-0.0742044 19.2543 -0.0742044 19.7481 0.222994 20.0527C0.371783 20.2052 0.566237 20.2812 0.761071 20.2812C0.955905 20.2812 1.15074 20.2052 1.29915 20.0527L18.2657 2.66308V19.5012C18.2657 19.9318 18.6067 20.2812 19.0268 20.2812C19.4469 20.2812 19.7878 19.9318 19.7878 19.5012V0.780048C19.7878 0.349462 19.4469 0 19.0268 0Z" fill="white" />
                                        </svg>
                                    </div>
                                    <div className='col-md-6 col-11'>
                                        <h4>{service.title}</h4>
                                    </div>
                                    <div className='col-md-4 col-12'>
                                        <p>{service.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default OurServices;
