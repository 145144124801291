import React, { useEffect, useRef } from "react";
import 'animate.css';
import Team1 from "../assets/images/team1.png";
import Team2 from "../assets/images/team2.png";
import Team3 from "../assets/images/team3.png";

export default function About() {
  const elementsRef = useRef([]);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate__animated', 'animate__slideInUp');
          observer.unobserve(entry.target);  // Only animate once
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    elementsRef.current.forEach(el => {
      if (el) {
        observer.observe(el);
      }
    });

    return () => {
      elementsRef.current.forEach(el => {
        if (el) {
          observer.unobserve(el);
        }
      });
    };
  }, []);

  return (
    <div className="about-section">
      <div className="container">
        <div className="heading-section ">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-12">
              <h2 ref={el => elementsRef.current.push(el)}>About Us</h2>
            </div>
            <div className="col-lg-1 col-md-1 col-12"></div>
            <div className="col-lg-7 col-md-7 col-12 ">
              <h5 className="mb-4" ref={el => elementsRef.current.push(el)}>Transforming Visions into Digital Masterpieces</h5>
              <p ref={el => elementsRef.current.push(el)}>
                At Sky Dreamers Club, we specialize in bringing your unique business visions to life through innovative digital solutions.
                With a collective expertise spanning over five decades, our team of seasoned professionals seamlessly blends creativity and
                technical prowess to craft digital experiences that captivate and inspire.
              </p>
            </div>
          </div>
        </div>

        <div className="about-images mt-5">
          <div className="row">
            <div className="col-md-8 col-12">
              <img src={Team1} className="img-fluid" ref={el => elementsRef.current.push(el)} />
            </div>
            <div className="col-md-4 col-12">
              <img src={Team2} className="img-fluid mobile-hide mb-4" ref={el => elementsRef.current.push(el)} />
              <img src={Team3} className="img-fluid mobile-hide" ref={el => elementsRef.current.push(el)} />
              <div className="row">
                <div className="col-6">
                  <img src={Team2} className="img-fluid desktop-hide mt-4" ref={el => elementsRef.current.push(el)} />
                </div>
                <div className="col-6">
                  <img src={Team3} className="img-fluid desktop-hide mt-4" ref={el => elementsRef.current.push(el)} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about-sections mt-5">
          <div className="row">
            <div className="col-md-4 col-12">
              <div className="about-box" ref={el => elementsRef.current.push(el)}>
                <h3>Mission</h3>
                <h6>Pioneering the Digital Frontier</h6>
                <p>
                  Driven by an unwavering commitment to excellence, we deliver exceptional services that transcend borders. Our mission is to
                  stay at the forefront of the ever-evolving digital landscape, guiding businesses through uncharted territories and emerging
                  technologies.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="about-box" ref={el => elementsRef.current.push(el)}>
                <h3>Vision</h3>
                <h6>Empowering Businesses to Thrive</h6>
                <p>
                  We are the catalysts of digital transformation, igniting dreams and propelling businesses to new heights. Through our
                  strategic approach, we aspire to empower organizations to surpass their goals, redefining what's possible and unlocking
                  their true potential.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="about-box" ref={el => elementsRef.current.push(el)}>
                <h3>Values</h3>
                <h6>Integrity, Innovation, and Client-Centric</h6>
                <p>
                  Integrity forms the foundation of our work. We embrace continuous innovation, staying ahead of the curve to meet the
                  ever-changing needs of our clients. Above all, we prioritize client satisfaction, ensuring that every digital solution we
                  create exceeds expectations and delivers tangible value.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
