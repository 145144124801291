import React from 'react'
import HomeScreen from '../componants/HomeScreen'
import FooterInfo from '../componants/FooterInfo'
import HomeTestomonials from '../componants/HomeTestomonials'
import CTASection from '../componants/CTASection'
import OurServices from '../componants/OurServices'
import AboutSection from '../componants/AboutSection'
import HomeMain from '../componants/HomeMain'

export default function Home() {
  return (
    <div>
        {/* <HomeScreen/>
        <OurServices/> */}
        <HomeMain/>
        <AboutSection/>
        <CTASection/>
        <HomeTestomonials/>
        <FooterInfo/>
    </div>
  )
}
