import React, { useState, useEffect, useRef } from "react";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import ScrollSmoother from 'gsap/dist/ScrollSmoother'
import LogoImg from "../assets/images/logo.png";
import Swal from 'sweetalert2';


const HomeMain = () => {
  // State for form data
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
    budget: "",
  });

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch(
        "https://lead-management-api.skydreamers.club/leads",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            projectId: "e888ec8e-1d34-46f9-bdbf-1c313106e32d",
            subject: "DIGITALMARKETING_BUSINESS",
            data: formData,
          }),
        }
      );
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      // Use SweetAlert for success message
      Swal.fire({
        icon: 'success',
        title: 'Thank you for contacting us!',
        text: 'We will get back to you.',
        showConfirmButton: false,
        timer: 1500  // Automatically close after 1.5 seconds
      });
  
      // Optionally reset form fields after submission
      setFormData({
        name: "",
        email: "",
        phoneNumber: "",
        message: "",
        budget: "",
      });
    } catch (error) {
      // Use SweetAlert for error message
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'There was an error submitting the form. Please try again.',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
  
      console.error("Error:", error);
    }
  };

  // Intersection Observer setup for animations
  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate-desktop");
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    if (ourServicesRef.current) {
      observer.observe(ourServicesRef.current);
    }

    desktopServicesRef.current.forEach((el) => {
      if (el) {
        observer.observe(el);
      }
    });

    const mobileObserverCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate-mobile");
        }
      });
    };

    const mobileObserver = new IntersectionObserver(
      mobileObserverCallback,
      observerOptions
    );

    mobileServicesRef.current.forEach((el) => {
      if (el) {
        mobileObserver.observe(el);
      }
    });

    return () => {
      if (ourServicesRef.current) {
        observer.unobserve(ourServicesRef.current);
      }

      desktopServicesRef.current.forEach((el) => {
        if (el) {
          observer.unobserve(el);
        }
      });

      mobileServicesRef.current.forEach((el) => {
        if (el) {
          mobileObserver.unobserve(el);
        }
      });
    };
  }, []);

  const services = [
    {
      title: "UI/UX Designing",
      description:
        "Creating intuitive and engaging user experiences that captivate and convert",
    },
    {
      title: "Website & App Development",
      description:
        "Building robust, scalable, and visually stunning websites and applications.",
    },
    {
      title: "Front End Development",
      description:
        "Crafting seamless and responsive interfaces using: HTML, CSS: The building blocks of web design",
    },
    {
      title: "ReactJs/NextJs",
      description:
        "Building robust, scalable, and visually stunning websites and applications.",
    },
    {
      title: "Flutter, JetPack, Swift",
      description: "Cutting-edge mobile app development.",
    },
    {
      title: "Shopify, WordPress",
      description: "Powerful e-commerce and content management solutions.",
    },
    {
      title: "Back End Development",
      description:
        "Ensuring powerful and efficient back-end systems with: Spring Boot: Enterprise-level Java applications.",
    },
    {
      title: "Node Js",
      description: "Fast and scalable server-side solutions.",
    },
    {
      title: "PHP",
      description: "Versatile and widely-used scripting language.",
    },
    { title: "MongoDB", description: "Flexible and scalable NoSQL databases" },
  ];

  const ourServicesRef = useRef(null);
  const desktopServicesRef = useRef([]);
  const mobileServicesRef = useRef([]);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate-desktop");
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    if (ourServicesRef.current) {
      observer.observe(ourServicesRef.current);
    }

    desktopServicesRef.current.forEach((el) => {
      if (el) {
        observer.observe(el);
      }
    });

    const mobileObserverCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate-mobile");
        }
      });
    };

    const mobileObserver = new IntersectionObserver(
      mobileObserverCallback,
      observerOptions
    );

    mobileServicesRef.current.forEach((el) => {
      if (el) {
        mobileObserver.observe(el);
      }
    });

    return () => {
      if (ourServicesRef.current) {
        observer.unobserve(ourServicesRef.current);
      }

      desktopServicesRef.current.forEach((el) => {
        if (el) {
          observer.unobserve(el);
        }
      });

      mobileServicesRef.current.forEach((el) => {
        if (el) {
          mobileObserver.unobserve(el);
        }
      });
    };
  }, []);

  gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

  useEffect(() => {
    let tl;

    const handleResize = () => {
      if (window.innerWidth >= 768) {
        if (!tl) {
          tl = gsap.timeline({
            scrollTrigger: {
              trigger: '.hero-section',
              start: 'top+=38% top',
              end: 'bottom top',
              pin: true,
              pinSpacing: false,
            },
          });
        }
      } else {
        if (tl) {
          tl.kill();
          ScrollTrigger.getAll().forEach((st) => st.kill()); // Ensure all ScrollTriggers are killed
          tl = null;
        }
      }
    };

    handleResize(); // Initial check

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (tl) {
        tl.kill();
      }
      ScrollTrigger.getAll().forEach((st) => st.kill()); // Clean up all ScrollTriggers
    };
  }, []);


  return (
    <div>
      <div className="hero-section">
        <div className="row" id="data">
          <div className="col-lg-6 col-12">
            <div className="container">
              <div className="main-hero">
                <a href="https://www.skydreamers.club/"><img src={LogoImg}  className="img-fluid main-logo" alt="Logo" /></a>
                <div className="hero-content">
                  <h1>
                    Designing and Developing for Your Unique Business Vision
                  </h1>
                  <p className="services-heading">
                    App Development <span>|</span> Website Development
                  </p>

                  <div className="form-section">
                    <h3 className="form-heading">
                      Let's Work Together! <span className="form-border"></span>
                    </h3>
                  </div>

                  <div className="main-form">
                    <form onSubmit={handleSubmit}>
                      <div className="col-12 mb-3">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            placeholder="Name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                          />
                          <label htmlFor="name">Name</label>
                        </div>
                      </div>
                      <div className="col-12 mb-3">
                        <div className="form-floating">
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="Email ID"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                          />
                          <label htmlFor="email">Email ID</label>
                        </div>
                      </div>
                      <div className="col-12 mb-3">
  <div className="form-floating">
    <input
      type="tel"
      className="form-control"
      id="phoneNumber"
      name="phoneNumber"
      placeholder="Mobile"
      value={formData.phoneNumber}
      onChange={handleInputChange}
      pattern="^\d{10}$"
      title="Please enter a valid phone number"
      required
    />
    <label htmlFor="phoneNumber">Mobile</label>
  </div>
</div>

                      <div className="col-12 mb-3 select-floting">
                        <div className="form-floating">
                          <select
                            className="form-control form-select"
                            id="budget"
                            name="budget"
                            aria-placeholder="Budget"
                            placeholder="Budget"
                            value={formData.budget}
                            onChange={handleInputChange}
                            required
                          >
                            <option value="" disabled>
                              Select Budget
                            </option>
                            <option value="50K - 1LAC">50K - 1LAC</option>
                            <option value="1LAC - 2LAC">1LAC - 2LAC</option>
                            <option value="2LAC - 5LAC">2LAC - 5LAC</option>
                            <option value="5LAC & Above">5LAC & Above</option>
                          </select>
                          <label htmlFor="budget">Budget</label>
                        </div>
                      </div>
                      <div className="col-12 mb-3">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="message"
                            name="message"
                            placeholder="Message"
                            value={formData.message}
                            onChange={handleInputChange}
                            required
                          />
                          <label htmlFor="message">Message</label>
                        </div>
                      </div>
                      <div className="col-12 mb-3">
                        <div className="submit-button">
                          <button type="submit" className="btn btn-primary mb-4">
                            Submit{" "}
                            <i className="fa-solid fa-arrow-right-long mx-2"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="hero-img">
              {/* <img src={HeroImg} className='img-fluid mobile-hide' alt='Hero' /> */}
            </div>
          </div>
        </div>
      </div>

      <div className="our-services" >
        <div className="container">
          <h2>Our Services</h2>

          <div className="mobile-hide">
            <div className="services-boxed">
              {services.map((service, index) => (
                <div
                  key={index}
                  className="services-main"
                  ref={(el) => (desktopServicesRef.current[index] = el)}
                >
                  <div className="row">
                    <div className="col-md-1 col-1">
                      {/* SVG Icons (Adjust SVG paths as needed) */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="mobile-hide mx-auto"
                        width="39"
                        height="39"
                        viewBox="0 0 39 39"
                        fill="none"
                      >
                        <path
                          d="M36.8089 0H1.47236C0.659615 0 0 0.659615 0 1.47236C0 2.2851 0.659615 2.94471 1.47236 2.94471H33.2539L0.4314 35.7679C-0.143555 36.3429 -0.143555 37.2749 0.4314 37.8498C0.719246 38.1377 1.09543 38.2812 1.47236 38.2812C1.84928 38.2812 2.2262 38.1377 2.51331 37.8498L35.3365 5.02662V36.8089C35.3365 37.6216 35.9962 38.2812 36.8089 38.2812C37.6216 38.2812 38.2812 37.6216 38.2812 36.8089V1.47236C38.2812 0.659615 37.6216 0 36.8089 0Z"
                          fill="white"
                        />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="desktop-hide mx-auto"
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                      >
                        <path
                          d="M19.0268 0H0.761071C0.34096 0 0 0.349462 0 0.780048C0 1.21063 0.34096 1.5601 0.761071 1.5601H17.1892L0.222994 18.9497C-0.0742044 19.2543 -0.0742044 19.7481 0.222994 20.0527C0.371783 20.2052 0.566237 20.2812 0.761071 20.2812C0.955905 20.2812 1.15074 20.2052 1.29915 20.0527L18.2657 2.66308V19.5012C18.2657 19.9318 18.6067 20.2812 19.0268 20.2812C19.4469 20.2812 19.7878 19.9318 19.7878 19.5012V0.780048C19.7878 0.349462 19.4469 0 19.0268 0Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div className="col-md-6 col-11">
                      <h4>{service.title}</h4>
                    </div>
                    <div className="col-md-4 col-12">
                      <p>{service.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="desktop-hide">
            <div className="services-boxed">
              {services.map((service, index) => (
                <div
                  key={index}
                  className="services-mainmobile"
                  ref={(el) => (mobileServicesRef.current[index] = el)}
                >
                  <div className="row">
                    <div className="col-md-1 col-1">
                      {/* SVG Icons (Adjust SVG paths as needed) */}
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 20 21" fill="none">
  <path d="M19.0268 0H0.761071C0.34096 0 0 0.349462 0 0.780048C0 1.21063 0.34096 1.5601 0.761071 1.5601H17.1892L0.222994 18.9497C-0.0742044 19.2543 -0.0742044 19.7481 0.222994 20.0527C0.371783 20.2052 0.566237 20.2812 0.761071 20.2812C0.955905 20.2812 1.15074 20.2052 1.29915 20.0527L18.2657 2.66308V19.5012C18.2657 19.9318 18.6067 20.2812 19.0268 20.2812C19.4469 20.2812 19.7878 19.9318 19.7878 19.5012V0.780048C19.7878 0.349462 19.4469 0 19.0268 0Z" fill="white"/>
</svg>
                    </div>
                    <div className="col-md-6 col-11">
                      <h4>{service.title}</h4>
                    </div>
                    <div className="col-md-4 col-12">
                      <p>{service.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeMain;
